import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Luis Valerio', // e.g: 'Name | Developer'
  lang: 'es', // e.g: en, es, fr, jp
  description: 'My personal web site', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hola, mi nombre es',
  name: 'Luis Valerio',
  subtitle: 'Soy Ingeniero de Software',
  cta: 'Saber más',
};

// ABOUT DATA
export const aboutData = {
  title: 'Breve introducción',
  img: 'about.png',
  paragraphOne:
    'Hola de nuevo, te platico más acerca de mi. Soy Ingeniero de Software, mi especialidad es el backend. Tengo más de 7 años de experiencia desarrollando aplicaciones web y aunque mi especialidad es el backend (Java), también disfruto el desarrollo frontend (especialmente usando Vue.js).',
  paragraphTwo1: 'Actualmente, estoy trabajando como Líder Técnico en ',
  paragraphTwo2:
    ', aquí estamos trabajando en un proyecto con una importante compañía de telecomunicaciones en México. ',
  paragraphThree1: 'Si es de tu interés, puedes descargar mi curriculum',
  paragraphThreelabelLink: '',
  paragraphThree2: ', o sigue abajo para conocer mi perfil técnico.',
  resume: 'LuisValerio.pdf', // if no resume, the button will not show up
  resumeLabel: 'Descargar curriculum',
};

export const titlesData = {
  techTitle: 'Resumen de mi perfil técnico',
  projectsTitle: 'Proyectos creados',
};

export const techData = [
  {
    id: nanoid(),
    img: 'java.png',
    title: 'Java programming lenguage.',
  },
  {
    id: nanoid(),
    img: 'spring.png',
    title: 'Spring framework for Java',
  },
  {
    id: nanoid(),
    img: 'javascript.png',
    title: 'JavaScript programming language.',
  },
  {
    id: nanoid(),
    img: 'vuejs.png',
    title: 'JavaScript Framework.',
  },
  {
    id: nanoid(),
    img: 'mongodb.png',
    title: 'Date Base NoSQL based in files.',
  },
  {
    id: nanoid(),
    img: 'mysql.png',
    title: 'MySQL data base.',
  },
  {
    id: nanoid(),
    img: 'jenkins.png',
    title: 'Jenkins tool for CI/CD.',
  },
  {
    id: nanoid(),
    img: 'teamcity.png',
    title: 'Team City tool made by Jetbreans for CI/CD.',
  },
  {
    id: nanoid(),
    img: 'activemq.png',
    title: 'Message broker.',
  },
  {
    id: nanoid(),
    img: 'docker.png',
    title: 'Docker containers.',
  },
  {
    id: nanoid(),
    img: 'linux.png',
    title: 'Linux Operating System.',
  },
  {
    id: nanoid(),
    img: 'nginx.png',
    title: 'Web server, proxy and load balancer. ',
  },
];

// CONTACT DATA
export const contactData = {
  title: 'Contáctame',
  cta: 'Por favor envíame un correo a ',
  btn: '',
  email: 'antio.valerio@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/antio_valerio',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/antiov',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/antiovalerio',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/antiov',
    },
  ],
  copyright: 'Sitio creado por ',
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};

export const projectsData = [
  {
    id: nanoid(),
    img: 'auditarte.png',
    url: 'https://www.auditarte.mx',
    title: 'Auditarte',
    description: `Landing page de lanzamiento de aplicación móvil para gestión de auditorías. En
    la solución se incluyó configuración de Google Analytics y Google Tag Manager.`,
  },
  {
    id: nanoid(),
    img: 'machathon2022.png',
    url: 'https://machathon-2022.devpost.com/',
    title: 'Machathon 2022',
    description: `Participación en el concurso creando en colaboración de equipo una aplicación con
    tecnologías como Pusher, NextJS, CockroachDB, Prisma ORM.`,
  },
];
